<template>
    <div style="max-width: 1360px;position: relative;margin: 0 auto;overflow: scroll;">
        <div style="margin-top: 26px;margin-left: 12px;margin-right: 12px">
            <a href="/"
               style="opacity:0.9;color: #fff;float: left;text-decoration:none;display: flex;-webkit-box-align: center;align-items: center;font-weight: 600;font-size: 24px;cursor: pointer;">
                <img src="../../public/logo.png" style="width: 40px;margin-right: 12px"/>
                HETC OG NFT
            </a>
            <div style="float: right;margin-top: 9px;">


                <span v-if="addr==''" @click="load" class="walletBtn">
                    <span>Connect to a wallet</span>
                </span>
                <span @click="myshowfn" v-if="addr!=''" class="walletBtn">
                    <span v-if="addr!=''">My Og:{{subname1(addr)}}</span>
                </span>
                <a href="https://twitter.com/EtcHebeBlock" style="padding-right: 10px">
                    <img style="width: 26px" src="../../public/twitter.png">
                </a>
                <a href="https://t.me/hebeOffice" style="padding-right: 10px">
                    <img style="width: 26px" src="../../public/telegram.png">
                </a>
                <a href="https://discord.gg/HFBUJ3AKFT">
                    <img style="width: 26px" src="../../public/discord.png">
                </a>
            </div>
        </div>
        <div style="margin-top: 12%;margin-left: 12px;margin-right: 12px">
            <van-row class="mmmm" type="flex" justify="space-between">
                <van-col class="van-col--48" span="12">
                    <div>
                        <div class="txt1" style="font-weight: 900;color: #fff;    overflow: hidden;">
                            <div>
                                Welcome to
                            </div>
                            <div href="/"
                                 style="color: #fff;float: left;display: flex;-webkit-box-align: center;align-items: center;">
                                Hebe Etc Og Nft
                                <img src="../../public/og.png"/>
                            </div>
                        </div>
                        <div class="txt2" style="opacity: 0.7;color: #fff;margin-right: 20%;">
                            We are seeking community sponsorship to support our development efforts on the Ethereum
                            Classic network. With your support, the HEBE team will be able to continue building and
                            maintaining vital infrastructure, including ETCSCAN, the ETC RPC, the ETC desktop wallet,
                            and the ETC Google Chrome extension wallet.
                            <br><br>
                            By owning an HETC OG NFT from HEBE, you demonstrate your support for our work and show your
                            endorsement of our mission to strengthen the Ethereum Classic network. Every NFT holder will
                            be held in high regard by the community and will play a crucial role in ensuring the success
                            of our development efforts.
                        </div>
                    </div>
                </van-col>
                <van-col class="van-col--48" span="12">
                    <div>
                        <div style="margin-top: 0">
                            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                                <van-swipe-item>
                                    <div style="position: relative;float: left;">
                                        <img class="card" src="../../public/card.png">
                                        <div style="position: absolute;top: 0;width: 100%;">
                                            <div class="cardimg">
                                                <div>
                                                    <img style="height: auto" src="../../public/127.png"/>
                                                </div>
                                                <div class="cardtxt">
                                                    Bottts
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </van-swipe-item>
                                <van-swipe-item>
                                    <div style="position: relative;float: left;">
                                        <img class="card" src="../../public/card.png">
                                        <div style="position: absolute;top: 0;width: 100%;">
                                            <div class="cardimg">
                                                <div>
                                                    <img style="height: auto" src="../../public/2339.png"/>
                                                </div>
                                                <div class="cardtxt">
                                                    Avataaars
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </van-swipe-item>
                                <van-swipe-item>
                                    <div style="position: relative;float: left;">
                                        <img class="card" src="../../public/card.png">
                                        <div style="position: absolute;top: 0;width: 100%;">
                                            <div class="cardimg">
                                                <div>
                                                    <img style="height: auto" src="../../public/2900.png"/>
                                                </div>
                                                <div class="cardtxt">
                                                    Gridy Avatars
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </van-swipe-item>
                                <van-swipe-item>
                                    <div style="position: relative;float: left;">
                                        <img class="card" src="../../public/card.png">
                                        <div style="position: absolute;top: 0;width: 100%;">
                                            <div class="cardimg">
                                                <div>
                                                    <img style="height: auto" src="../../public/3.png"/>
                                                </div>
                                                <div class="cardtxt">
                                                    Pixel Art
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </van-swipe-item>
                                <van-swipe-item>
                                    <div style="position: relative;float: left;">
                                        <img class="card" src="../../public/card.png">
                                        <div style="position: absolute;top: 0;width: 100%;">
                                            <div class="cardimg">
                                                <div>
                                                    <img style="height: auto" src="../../public/51.png"/>
                                                </div>
                                                <div class="cardtxt">
                                                    Pixel Art Neutral
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </van-swipe-item>
                            </van-swipe>

                        </div>
                        <div style="font-size: 18px;color: #fff;text-align: center">
                            {{countSum}} / 12000 (mint available now)
                        </div>
                        <div style="text-align: center;margin-top: 20px">
                            <van-stepper input-width="120px" button-size="40px" style="width: 100%" v-model="sum"
                                         min="1" max="10" integer/>

                        </div>
                        <div style="font-size: 18px;color: #fff;text-align: center;margin-top: 20px">
                            {{2*sum}} ETC
                        </div>
                        <van-row style="padding-bottom: 80px;padding-top: 20px" type="flex" justify="space-between">
                            <van-col span="12">
                                <div @click="mintnft" class="mintbtn" style="margin-right: 10px">
                                    Mint Nft
                                </div>
                            </van-col>
                            <van-col span="12">
                                <div @click="showfn" class="mintbtn1" style="margin-left: 10px">
                                    View All
                                </div>
                            </van-col>
                        </van-row>

                    </div>
                </van-col>
            </van-row>
            <a href="https://etcscan.cc/token/0x67c7d60c66c77b8dc433af8668c3b301100d2d9d&&type=Code">
                Contracts:{{nftaddr}}
            </a>
            <br> <br>
            <div style="padding-bottom: 100px;color: #fff;opacity: 0.2">


                Bottts：This implementation is a remix of Bottts by Pablo Stanley. Licensed under Free for personal and
                commercial use.
                <br> <br>
                Avataaars：This implementation is a remix of Avataaars by Pablo Stanley. Licensed under Free for personal
                and commercial use.
                <br><br>
                Gridy Avatars： This implementation is Gridy Avatars. Licensed is MIT.
                <br><br>
                Pixel Art：This implementation is a remix of Pixel Art by Florian Körner. Licensed under CC0 1.0.
                <br><br>
                Pixel Art Neutral：This implementation is a remix of Pixel Art Neutral by Florian Körner. Licensed under
                CC0 1.0.
            </div>
        </div>
        <van-popup closeable
                   v-model="show" position="top" :style="{ height: '100%' }">
            <div style="max-width: 1360px;position: relative;margin: 0 auto;overflow: scroll;">
                <div style="margin-top: 40px">
                    <van-search
                            v-model="search"
                            show-action
                            placeholder="Search by HETC number..."
                    >
                        <template #action>
                            <div @click="onSearch">Search</div>
                        </template>
                    </van-search>
                </div>
                <div style="margin-top: 40px">
                    <van-list
                            loading-text="Loading"
                            finished-text="Finished">
                        <van-row gutter="20">
                            <van-col class="listPc" v-for="item in list" span="6">
                                <div style="position:relative;">
                                    <img style="width: 100%" src="../../public/card.png">
                                    <div style="position: absolute;top: 0;width: 100%;">
                                        <div style="text-align: center;margin-top: 15%">
                                            <div>
                                                <img style="height: auto;width: 30%"
                                                     :src="'https://json.hens.domains/og/img/'+item+'.png'"/>
                                            </div>
                                            <div style="color: #fff">
                                                # {{item}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </van-col>
                            <van-col class="listIphone" v-for="item in list" span="12">
                                <div style="position:relative;">
                                    <img style="width: 100%" src="../../public/card.png">
                                    <div style="position: absolute;top: 0;width: 100%;">
                                        <div style="text-align: center;margin-top: 15%">
                                            <div>
                                                <img style="height: auto;width: 30%"
                                                     :src="'https://json.hens.domains/og/img/'+item+'.png'"/>
                                            </div>
                                            <div style="color: #fff">
                                                # {{item}}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </van-col>
                        </van-row>
                    </van-list>

                    <div v-if="!finished" @click="onLoad" class="mintbtn1"
                         style="margin-left: 10px;margin-top: 40px;margin-bottom: 40px">
                        Load More
                    </div>
                </div>
            </div>
        </van-popup>
        <van-popup closeable
                   v-model="myshow" position="top" :style="{ height: '100%' }">
            <div style="max-width: 1360px;position: relative;margin: 0 auto;overflow: scroll;">
                <div style="margin-top: 40px">
                    <van-search
                            v-model="search1"
                            show-action
                            placeholder="Search by HETC number..."
                    >
                        <template #action>
                            <div @click="onSearch1">Search</div>
                        </template>
                    </van-search>
                </div>
                <div style="margin-top: 40px">
                    <van-row gutter="20">
                        <van-col class="listPc" v-for="item in list2" span="6">
                            <div style="position:relative;">
                                <img style="width: 100%" src="../../public/card.png">
                                <div style="position: absolute;top: 0;width: 100%;">
                                    <div style="text-align: center;margin-top: 15%">
                                        <div>
                                            <img style="height: auto;width: 30%"
                                                 :src="'https://json.hens.domains/og/img/'+item+'.png'"/>
                                        </div>
                                        <div style="color: #fff">
                                            # {{item}}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </van-col>
                        <van-col class="listIphone" v-for="item in list2" span="12">
                            <div style="position:relative;">
                                <img style="width: 100%" src="../../public/card.png">
                                <div style="position: absolute;top: 0;width: 100%;">
                                    <div style="text-align: center;margin-top: 15%">
                                        <div>
                                            <img style="height: auto;width: 30%"
                                                 :src="'https://json.hens.domains/og/img/'+item+'.png'"/>
                                        </div>
                                        <div style="color: #fff">
                                            # {{item}}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </van-col>
                    </van-row>
                </div>
            </div>
        </van-popup>

    </div>
</template>

<script>
    // let network = {
    //     chainId: '0x3f',
    //     rpcUrls: ['https://ethercluster.com/mordor'],
    //     chainName: 'Ethereum Classic Mordor',
    //     nativeCurrency: {name: 'ETC', decimals: 18, symbol: 'ETC'},
    //     blockExplorerUrls: ['https://blockscout.com/etc/mordor/']
    // }
    let network = {
        chainId: '0x3d',
        rpcUrls: ['https://etc.etcdesktop.com'],
        chainName: 'Ethereum Classic',
        nativeCurrency: { name: 'ETC', decimals: 18, symbol: 'ETC' },
        blockExplorerUrls: ['https://blockscout.com/etc/mainnet'],
        iconUrls: ['https://hebe.cc/assets/logo/etc_logo.png', 'https://hebe.cc/assets/logo/etc_logo.png']
    }
    import {Toast} from 'vant';
    import axios from 'axios';

    export default {
        name: "index",
        data() {
            return {
                search1: '',
                myshow: false,
                sum: 1,
                show: false,
                list: [],
                loading: false,
                finished: false,
                search: '',
                addr: '',
                nftaddr: '0x67c7d60c66C77B8Dc433aF8668C3b301100d2D9d',
                countSum: 1,
                list1: [],
                list2: []

            };
        },
        methods: {
            showfn() {
                this.show = true;
                this.onLoad();
            },
            async myshowfn() {
                this.myshow = true;
                let nftIndex = await this.balanceOf();
                this.list1 = []
                this.list2 = []
                for (let i = 0; i < nftIndex; i++) {
                    let nftid = await this.tokenOfOwnerByIndex(i)
                    this.list1.push(nftid)
                    this.list2.push(nftid)
                }
            },
            onSearch1() {
                if (this.search1 != '') {
                    this.finished = true;
                    this.list2 = []
                    this.list1.forEach(item => {
                        if (this.search1 == item) {
                            this.list2 = [item]
                        }
                    })

                } else {
                    this.list2 = this.list1
                }

            },
            tokenOfOwnerByIndex(index) {
                return new Promise(async (resolve, reject) => {
                    let data = this.$web3.eth.abi.encodeFunctionCall({
                        name: 'tokenOfOwnerByIndex',
                        type: 'function',
                        inputs: [{
                            "type": "address",
                            "name": "owner"
                        },
                            {
                                "type": "uint256",
                                "name": "index"
                            }]
                    }, [this.addr, index])
                    axios.post(network.rpcUrls[0], {
                        'jsonrpc': '2.0',
                        'method': 'eth_call',
                        'params': [{
                            'to': this.nftaddr,
                            'data': data
                        }, 'latest'],
                        'id': 66
                    }).then(async res => {
                        if (res.data.result) {
                            resolve(this.$web3.utils.hexToNumberString(res.data.result))
                        }
                    })
                });
            },
            balanceOf() {
                return new Promise(async (resolve, reject) => {

                    let data = this.$web3.eth.abi.encodeFunctionCall({
                        name: 'balanceOf',
                        type: 'function',
                        inputs: [{
                            "type": "address",
                            "name": "owner"
                        }]
                    }, [this.addr])
                    axios.post(network.rpcUrls[0], {
                        'jsonrpc': '2.0',
                        'method': 'eth_call',
                        'params': [{
                            'to': this.nftaddr,
                            'data': data
                        }, 'latest'],
                        'id': 66
                    }).then(async res => {
                        if (res.data.result) {
                            resolve(this.$web3.utils.hexToNumberString(res.data.result))
                        }
                    })
                });
            },
            async mintnft() {
                if (this.addr == "") {
                    Toast('Not connected to ETC network');
                    return;
                }
                let data = this.$web3.eth.abi.encodeFunctionCall({
                    name: 'mintNFT',
                    type: 'function',
                    inputs: [{
                        type: 'uint256',
                        name: 'amount'
                    }]
                }, [this.sum])
                const transactionParameters = {
                    to: this.nftaddr,
                    gasPrice: this.$web3.utils.toHex((1 * 10e+8)),
                    from: this.addr,
                    value: this.$web3.utils.toHex(parseInt(2 * this.sum * 10e+17)),
                    data: data,
                    chainId: network.chainId
                }
                const txHash = await ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [transactionParameters]
                })
                Toast('MintNFT Success:'+txHash);


            },
            subname1(txt) {
                if (txt) {
                    if (txt.length > 41) {
                        return txt.substring(0, 8) + '...' + txt.substring(36, 42)
                    } else {
                        return txt
                    }
                }
            },
            async etclogin() {
                return new Promise(async (resolve, reject) => {
                    let addr = await this.getAddr()
                    let chainId = await ethereum.request({method: 'eth_chainId'})
                    if (chainId != network.chainId) {
                        await this.addEthereumChain()
                    }
                    resolve(addr)
                })
            },
            async addEthereumChain() {
                await ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        network
                    ]
                })
            },
            async getAddr() {
                return new Promise(async (resolve, reject) => {
                    try {
                        webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({
                            method: 'getAddr',
                            args: ['222', 'etc']
                        }))
                        if (ethereum.selectedAddress == null) {

                            setTimeout(() => {
                                resolve(this.getAddr())
                            }, 1000)
                        } else {

                            resolve(ethereum.selectedAddress)
                        }
                    } catch (e) {
                        if (window.ethereum) {
                            ethereum
                                .request({method: 'eth_requestAccounts'})
                                .then((res) => {
                                    resolve(res[0])
                                })
                                .catch((err) => {
                                    resolve('')
                                })
                        } else {
                            Toast('Not connected to ETC network');
                        }
                    }

                })
            },
            onSearch() {
                if (this.search != '') {
                    this.finished = true;
                    this.list = [this.search]
                }
                if (this.search == '') {
                    this.finished = false;
                    this.list = []
                }
                this.onLoad()
            },
            onLoad() {
                if (!this.finished) {
                    for (let i = 0; i < 10; i++) {
                        this.list.push(this.list.length + 1);
                    }
                    this.loading = false;
                    if (this.list.length >= 12000) {
                        this.finished = true;
                    }

                }
            },
            async load() {
                this.totalSupply()
                this.addr = await this.etclogin()

            },
            totalSupply() {
                let data = this.$web3.eth.abi.encodeFunctionCall({
                    name: 'totalSupply',
                    type: 'function',
                    inputs: []
                }, [])
                axios.post(network.rpcUrls[0], {
                    'jsonrpc': '2.0',
                    'method': 'eth_call',
                    'params': [{
                        'to': this.nftaddr,
                        'data': data
                    }, 'latest'],
                    'id': 66
                }).then(async res => {
                    if (res.data.result) {
                        this.countSum = this.$web3.utils.hexToNumberString(res.data.result)
                    }
                })
            }
        },
        async mounted() {
            this.load()
            if (window.ethereum) {
                ethereum.on('accountsChanged', (accounts) => {
                    this.load()
                })
            }
        }
    }
</script>

<style>
    .walletBtn {
        margin-top: -5px;
        float: left;
        margin-right: 10px;
        text-align: center;
        outline: none;
        text-decoration: none;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        font-size: 16px;
        padding: 0.5rem;
        border-radius: 12px;
        cursor: pointer;
        user-select: none;
        font-weight: 500;
        background-color: rgba(55, 107, 173, 0.44);
        border: 1px solid rgba(55, 107, 173, 0.44);
        color: rgb(109, 168, 255)
    }

    .walletBtn span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0px 0.5rem 0px 0.25rem;
        font-size: 1rem;
        width: fit-content;
        font-weight: 500;
    }

    @media screen and (max-width: 1380px) {

        .txt1 {
            font-size: 48px !important;
        }

        .txt1 img {
            width: 60px !important;
        }

        .txt2 {
            font-size: 18px !important;

        }

        .my-swipe .van-swipe-item .card {
            height: 350px !important;
            width: 500px !important;
        }

        .my-swipe .van-swipe-item .cardimg {
            margin-top: 20% !important;
        }

        /*.my-swipe .van-swipe-item .cardimg img {*/
        /*    width: 100px !important;*/
        /*}*/
        .cardtxt1 {
            font-size: 18px !important;
        }

        .my-swipe .van-swipe-item .cardtxt {
            font-size: 20px !important;

        }

        .mintbtn {
            height: 46px !important;
            line-height: 30px !important;
            font-size: 18px !important;
        }

        .mintbtn1 {
            height: 46px !important;
            line-height: 30px !important;
            font-size: 18px !important;
        }

    }

    @media screen and (max-width: 1180px) {
        .mmmm .van-col--48 {
            width: 100%;
        }

        .txt1 {
            font-size: 48px !important;
        }

        .txt1 img {
            width: 60px !important;
        }

        .txt2 {
            font-size: 18px !important;

        }

        .my-swipe .van-swipe-item .card {
            height: 350px !important;
            width: 500px !important;
        }

        .my-swipe .van-swipe-item .cardimg {
            margin-top: 18% !important;
        }

        /*.my-swipe .van-swipe-item .cardimg img {*/
        /*    width: 100px !important;*/
        /*}*/
        .cardtxt1 {
            font-size: 18px !important;
        }

        .my-swipe .van-swipe-item .cardtxt {
            font-size: 20px !important;

        }

        .mintbtn {
            height: 46px !important;
            line-height: 30px !important;
            font-size: 18px !important;
        }

        .mintbtn1 {
            height: 46px !important;
            line-height: 30px !important;
            font-size: 18px !important;
        }

    }

    .listIphone {
        display: none;
    }

    .listPc {
        display: inline;
    }

    @media screen and (max-width: 720px) {
        .listPc {
            display: none;
        }

        .listIphone {
            display: inline;
        }

        .mmmm .van-col--48 {
            width: 100%;
        }

        .mmmm {
            width: 100%;
        }

        .txt1 {
            margin-top: 10% !important;
            font-size: 28px !important;
        }

        .txt1 img {
            width: 40px !important;
        }

        .txt2 {
            font-size: 16px !important;
            margin-right: 4% !important;;

        }

        .my-swipe .van-swipe-item .card {
            height: auto !important;
            width: 100% !important;
        }

        .my-swipe .van-swipe-item .cardimg {
            margin-top: 22% !important;
        }

        /*.my-swipe .van-swipe-item .cardimg img {*/
        /*    width: 40px !important;*/
        /*}*/
        .cardtxt1 {
            font-size: 14px !important;
            margin-right: 26% !important;
            margin-top: 26% !important;
            line-height: 24px !important;

        }

        .my-swipe .van-swipe-item .cardtxt {
            font-size: 14px !important;

        }

        .mintbtn {
            height: 46px !important;
            line-height: 30px !important;
            font-size: 18px !important;
        }

        .mintbtn1 {
            height: 46px !important;
            line-height: 30px !important;
            font-size: 18px !important;
        }

    }

    .txt1 img {
        width: 80px;
        margin-left: 12px
    }

    .txt1 {
        font-size: 68px;
    }

    .txt2 {
        font-size: 20px;
        margin-top: 20px;
        line-height: 30px;
    }

    .mintbtn {
        background: #3f2e89;
        border: 5px solid #6645db;
        box-shadow: 0 10px 20px rgb(0 0 0 / 50%);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 56px;
        line-height: 35px;
        color: #fff;
        font-weight: 700;
        font-size: 20px;
        cursor: pointer;

    }

    .mintbtn1 {
        background: rgba(103, 88, 140, .53);
        border: 5px solid rgba(103, 88, 140, .53);
        box-shadow: 0 10px 20px rgb(0 0 0 / 50%);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 56px;
        line-height: 35px;
        color: #fff;
        font-weight: 700;
        font-size: 20px;
        cursor: pointer;

    }

    .my-swipe .van-swipe-item .card {
        height: 400px;
        width: 660px;
    }

    .my-swipe .van-swipe-item .cardimg {
        margin-top: 12%;
        text-align: center;
    }

    .my-swipe .van-swipe-item .cardimg img {
        width: 25%;
    }

    .my-swipe .van-swipe-item .cardtxt {
        font-size: 24px;
        color: #fff;
        margin-top: 2%
    }

    .my-swipe .van-swipe-item .cardtxt1 {
        margin-top: 28%;
        color: #fff;
        margin-right: 36%;
        font-size: 24px;
        line-height: 30px

    }

    .van-popup {
        background-color: #191433 !important;
    }

    .van-search {
        background-color: inherit !important;
    }

    .van-search__action div {
        color: #fff;
    }

    .van-search__action:active {
        background-color: inherit !important;
        color: #191433 !important;

    }

    .van-search__action:active div {
        color: #191433 !important;

    }

</style>
